body {
  margin: 0;
  padding: 0;
}

.App {
  background-color: #f7f6f2;
  position: relative;
  padding: 30px 70px;
  overflow: hidden;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

header {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}

header img {
  width: 100%;
  max-width: 135px;
}

.background-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

::placeholder {
  color: $white !important;
}

/* ===== HEADER */

.header-toggle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #0a1f19;
  background-color: rgba(25, 77, 62, 0.08);
  display: none;
  justify-content: center;
  align-items: center;
  padding: 12px;
  margin-right: 16px;
  position: relative;
  z-index: 11;
}

.mobile-nav--active .header-toggle {
  border-color: #f7e733;
}

.header-toggle svg {
  display: block;
  width: 100%;
  height: 100%;
}

.header-logo {
  margin-right: 27px;
  position: relative;
  z-index: 11;
  width: 100%;
  max-width: 135px;
}

.header-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 35px;
  margin: 0;
  margin-left: auto;
}

.header-list__item {
  color: #0a1f19;
  text-decoration: none;
  padding-bottom: 9px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: 0.25s ease-in-out all;
}

.header-list__item:hover {
  border-bottom-color: #194d3e;
}

.header-list__modal-container {
  height: 41px;
}

.header-list__button {
  color: #0a1f19;
  border: 1px solid #0a1f19;
  border-radius: 100px;
  background-color: rgba(25, 77, 62, 0.08);
  transition: 0.15s linear;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 24px;
  text-decoration: none;
  position: relative;
  z-index: 11;
  cursor: pointer;
  overflow: hidden;
  height: 100%;
}

.mobile-nav--active .header-list__button {
  border-color: #f7e733;
  color: #fff;
}

.header-list__button:hover {
  background-color: rgba(25, 77, 62, 0.18);
}

.header-list__button span {
  position: relative;
  top: 0;
  transition: top 0.5s ease-in-out;
  margin-right: 10px;
}

.header-list__button span::before {
  content: attr(data-text);
  position: absolute;
  top: -68.5px;
  line-height: 1.25rem;
}

.header-list__button:hover span {
  top: 4.25rem;
}

/* ===== MOBILE NAV */
.mobile-nav {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #0a1f19;
  z-index: 10;
  padding-top: 96px;
  display: none;
}

.mobile-nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav li {
  border-top: 1px solid #194d3e;
}

.mobile-nav a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Sora', sans-serif;
  font-weight: 600;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' fill='none' viewBox='0 0 10 16'%3E%3Cpath fill='%23F7F6F2' fill-rule='evenodd' stroke='%23F7F6F2' stroke-linecap='round' stroke-linejoin='round' d='M1.2.7c-.3.3-.3.7 0 1L7.4 8l-6.2 6.3c-.3.3-.3.7 0 1 .3.3.7.3 1 0l6.6-6.8c.3-.3.3-.7 0-1L2.1.7a.7.7 0 0 0-.9 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 10px 16px;
  background-repeat: no-repeat;
  background-position: right 24px center;
}

.mobile-nav ul {
  border-bottom: #194d3e;
}

/* ===== MODAL */

.css-79ws1d-MuiModal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(10, 31, 25, 0.2);
  margin: 0 auto;
}

.css-1gh2ph0-MuiGrid-root,
.css-1fay00m-MuiGrid-root,
.css-re25zs-MuiGrid-root {
  max-width: 100% !important;
}

.swiper-slide .MuiGrid-root {
  max-width: none !important;
}

.swiper-slide .MuiPaper-root {
  background-color: transparent !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop,
.css-1wvcyoi-MuiPaper-root,
.css-8rm5q1-MuiPaper-root,
.css-yjcm3i-MuiPaper-root,
.css-1n8jxcv-MuiPaper-root,
.css-12k6hj9-MuiPaper-root {
  background-color: transparent !important;
}

.css-5v2d1h-MuiGrid-root > .MuiGrid-item {
  padding: 0;
}

.modal-close-button:hover {
  background-color: transparent !important;
}

.modal-close-button:hover svg {
  background-color: rgba(25, 77, 62, 0.25);
  border-radius: 100%;
  overflow: hidden;
}

.modal__button {
  color: #0a1f19;
  background-color: transparent;
  border: 2px solid #194d3e;
  border-radius: 100px;
  transition: 0.15s linear;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 0 24px;
  text-decoration: none;
  position: relative;
  z-index: 11;
  cursor: pointer;
  overflow: hidden;
  height: 39px;
}

.modal__button:hover {
  background-color: rgba(25, 77, 62, 0.18);
}

.modal__button span {
  position: relative;
  top: 0;
  transition: top 0.5s ease-in-out;
  margin-right: 10px;
}

.modal__button span::before {
  content: attr(data-text);
  position: absolute;
  top: -68.5px;
  line-height: 1.5rem;
}

.modal__button:hover span {
  top: 4.25rem;
}

@media (max-width: 820px) {
  .swiper {
    padding-bottom: 40px !important;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }

  .swiper-pagination-bullet-active {
    background-color: rgb(10, 31, 25) !important;
  }
}

/* ===== DATA GRID */

.MuiDataGrid-root {
  background-color: #fff;
}
.MuiDataGrid-root .MuiDataGrid-toolbarContainer {
  padding: 16px 24px;
  gap: 27px;
  overflow: auto;
}
.MuiDataGrid-root .MuiButton-root {
  color: #0a1f19;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-shrink: 0;
  text-transform: none;
  letter-spacing: normal;
}

.MuiDataGrid-root .MuiDataGrid-columnHeadersInner {
  background-color: #0a1f19;
  color: #fff;
  min-width: 100%;
}

.MuiDataGrid-columnHeaderTitle {
  width: 100%;
  font-size: 14px;
}

.MuiDataGrid-columnHeader {
  border-right: 1px solid #194d3e;
}
.MuiDataGrid-columnHeader--sortable {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='18' fill='none' viewBox='0 0 8 18'%3E%3Cpath fill='%23fff' d='M3.4.2.2 3.8C-.2 4.3.2 5 .8 5h6.4c.7 0 1-.7.6-1.2L4.6.2a.8.8 0 0 0-1.2 0Z'/%3E%3Cpath fill='%23fff' d='M3.4 17.8.2 14.2c-.4-.5 0-1.2.6-1.2h6.4c.7 0 1 .7.6 1.2l-3.2 3.6a1 1 0 0 1-1.2 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 8px 18px;
  background-position: right 24px center;
}

.MuiDataGrid-columnHeader--sortable .MuiDataGrid-columnHeaderTitle {
  padding-right: 20px;
}

.MuiDataGrid-columnHeader:last-child {
  border-right: 0;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiTablePagination-root:last-child {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* ===== POPUP */
.popup {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(25, 77, 62, 0.6);
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__logo {
  display: inline-block;
  margin-bottom: 28px;
  max-width: 100px;
  width: 100%;
  height: auto;
}

.popup__gold-bar {
  position: absolute;
  right: 0;
  bottom: 0;
}

.popup__stamp {
  position: absolute;
  bottom: -50px;
  right: -40px;
  z-index: 1;
}

.popup__button {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #0a1f19;
  background-color: rgba(25, 77, 62, 0.08);
  cursor: pointer;
  transition: 0.15s linear;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none' viewBox='0 0 10 10'%3E%3Cpath fill='%230A1F19' fill-rule='evenodd' d='M5 4.1 9.1 0l.9.9L5.9 5 10 9.1l-.9.9L5 5.9.9 10 0 9.1 4.1 5 0 .9.9 0 5 4.1Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
}

.popup__button:hover {
  background-color: rgba(25, 77, 62, 0.18);
}

/* ===== CTA */

.cta {
  border: 1px solid #194d3e;
  background-color: rgba(25, 77, 62, 0.13);
  border-radius: 16px;
  margin-bottom: 73px;
  padding: 32px 60px 40px;
  position: relative;
  overflow: hidden;
}

.cta__content {
  width: 100%;
  max-width: 374px;
  position: relative;
  z-index: 1;
}

.cta__content h2 {
  font-size: 38px;
  color: #0a1f19;
  margin-bottom: 25px;
}

.cta__content .cta__button {
  color: #0a1f19;
  border: 1px solid #0a1f19;
  border-radius: 100px;
  background-color: rgba(25, 77, 62, 0.08);
  transition: 0.15s linear;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 24px;
  text-decoration: none;
  overflow: hidden;
  height: 20px;
}

.cta__button:hover {
  background-color: rgba(25, 77, 62, 0.18);
}

.cta__button span {
  position: relative;
  top: 0;
  transition: top 0.5s ease-in-out;
  margin-right: 10px;
}

.cta__button span::before {
  content: attr(data-text);
  position: absolute;
  top: -68.5px;
  line-height: 1.25rem;
}

.cta__button:hover span {
  top: 4.25rem;
}

.cta__image {
  position: absolute;
  left: 50%;
  bottom: -60px;
  height: 120%;
  width: auto;
  object-fit: cover;
}

/* ===== FOOTER */

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer__logo a {
  text-decoration: none;
  color: #0a1f19;
  display: block;
}

.footer__logo img {
  display: block;
}
.footer__logo p {
  font-size: 13px;
  font-weight: 600;
}

.footer__nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 80px;
}

/* ===== MOBILE NAV */
.mobile-nav {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #0a1f19;
  z-index: 10;
  padding-top: 96px;
  display: none;
}

.mobile-nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav li {
  border-top: 1px solid #194d3e;
}

.mobile-nav a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px;
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-family: 'Sora', sans-serif;
  font-weight: 600;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='16' fill='none' viewBox='0 0 10 16'%3E%3Cpath fill='%23F7F6F2' fill-rule='evenodd' stroke='%23F7F6F2' stroke-linecap='round' stroke-linejoin='round' d='M1.2.7c-.3.3-.3.7 0 1L7.4 8l-6.2 6.3c-.3.3-.3.7 0 1 .3.3.7.3 1 0l6.6-6.8c.3-.3.3-.7 0-1L2.1.7a.7.7 0 0 0-.9 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-size: 10px 16px;
  background-repeat: no-repeat;
  background-position: right 24px center;
}

.mobile-nav ul {
  border-bottom: #194d3e;
}

/* ===== DATA GRID */

.MuiDataGrid-root {
  background-color: #fff;
}
.MuiDataGrid-root .MuiDataGrid-toolbarContainer {
  padding: 16px 24px;
  gap: 27px;
  overflow: auto;
}
.MuiDataGrid-root .MuiButton-root {
  color: #0a1f19;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-shrink: 0;
  text-transform: none;
  letter-spacing: normal;
}

.MuiDataGrid-root .MuiDataGrid-columnHeadersInner {
  background-color: #0a1f19;
  color: #fff;
  min-width: 100%;
}

.MuiDataGrid-columnHeaderTitle {
  width: 100%;
  font-size: 14px;
}

.MuiDataGrid-columnHeader {
  border-right: 1px solid #194d3e;
}
.MuiDataGrid-columnHeader--sortable {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='18' fill='none' viewBox='0 0 8 18'%3E%3Cpath fill='%23fff' d='M3.4.2.2 3.8C-.2 4.3.2 5 .8 5h6.4c.7 0 1-.7.6-1.2L4.6.2a.8.8 0 0 0-1.2 0Z'/%3E%3Cpath fill='%23fff' d='M3.4 17.8.2 14.2c-.4-.5 0-1.2.6-1.2h6.4c.7 0 1 .7.6 1.2l-3.2 3.6a1 1 0 0 1-1.2 0Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 8px 18px;
  background-position: right 24px center;
}

.MuiDataGrid-columnHeader--sortable .MuiDataGrid-columnHeaderTitle {
  padding-right: 20px;
}

.MuiDataGrid-columnHeader:last-child {
  border-right: 0;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiTablePagination-root:last-child {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* ===== POPUP */
.popup {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  background-color: rgba(25, 77, 62, 0.6);
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__logo {
  display: inline-block;
  margin-bottom: 28px;
  max-width: 100px;
  width: 100%;
  height: auto;
}

.popup__stamp {
  position: absolute;
  bottom: -50px;
  right: -40px;
}

.popup__button {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #0a1f19;
  background-color: rgba(25, 77, 62, 0.08);
  cursor: pointer;
  transition: 0.15s linear;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' fill='none' viewBox='0 0 10 10'%3E%3Cpath fill='%230A1F19' fill-rule='evenodd' d='M5 4.1 9.1 0l.9.9L5.9 5 10 9.1l-.9.9L5 5.9.9 10 0 9.1 4.1 5 0 .9.9 0 5 4.1Z' clip-rule='evenodd'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
}

.popup__button:hover {
  background-color: rgba(25, 77, 62, 0.18);
}

/* ===== CTA */

.cta {
  border: 1px solid #194d3e;
  background-color: rgba(25, 77, 62, 0.13);
  border-radius: 16px;
  margin-bottom: 73px;
  padding: 32px 60px 40px;
  position: relative;
  overflow: hidden;
}

.cta__content {
  width: 100%;
  max-width: 374px;
  position: relative;
  z-index: 1;
}

.cta__content h2 {
  font-size: 38px;
  color: #0a1f19;
  margin-bottom: 25px;
}
.cta__content a {
  color: #0a1f19;
  border: 1px solid #0a1f19;
  border-radius: 100px;
  background-color: rgba(25, 77, 62, 0.08);
  transition: 0.15s linear;
  display: inline-flex;
  align-items: center;
  gap: 5px;
  font-size: 15px;
  font-weight: 600;
  padding: 10px 24px;
  text-decoration: none;
}

.cta__content a:hover {
  background-color: rgba(25, 77, 62, 0.18);
}

.cta__image {
  position: absolute;
  left: 50%;
  bottom: -60px;
  height: 120%;
  width: auto;
  object-fit: cover;
}

/* ===== FOOTER */

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer__logo a {
  text-decoration: none;
  color: #0a1f19;
  display: block;
}

.footer__logo img {
  display: block;
}
.footer__logo p {
  font-size: 13px;
  font-weight: 600;
}

.footer__nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 80px;
}

.footer__nav h2 {
  font-size: 18px;
  font-family: 'Sora', sans-serif !important;
  margin-bottom: 11px;
}

.footer__nav h2 img {
  display: none;
}

.footer__nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.footer__nav a {
  text-decoration: none;
  color: #0a1f19;
  opacity: 0.7;
  font-size: 15px;
  transition: 0.15s linear;
}

.footer__nav a:hover {
  opacity: 1;
}

.footer__legal {
  width: 100%;
  margin-top: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding-bottom: 40px;
}

.footer__legal small {
  font-size: 9px;
}
.footer__legal a {
  color: currentColor;
}

.footer__legal a:hover {
  text-decoration: none;
}

/* ===== TABLET */

@media (max-width: 820px) {
  .App {
    padding: 16px;
  }

  .header-list {
    gap: 16px;
  }
}

/* ===== MOBILE */

@media (max-width: 736px) {
  .App {
    padding: 16px;
  }

  .header-toggle {
    display: flex;
  }

  .header-list li {
    display: none;
  }

  .header-list li:last-child {
    display: block;
  }

  header img {
    width: 70px;
    height: auto;
  }

  .mobile-nav--active .mobile-nav {
    display: block;
  }

  .popup {
    padding: 16px;
    justify-content: flex-start;
  }

  .popup__stamp {
    display: none;
  }

  .cta {
    padding: 40px 52px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 40px;
  }

  .cta img {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    margin-bottom: 23px;
    display: block;
  }

  .cta__content {
    text-align: center;
  }

  .cta__content h2 {
    font-size: 28px;
    margin-bottom: 24px;
    font-weight: normal;
  }

  .footer {
    flex-direction: column;
  }

  .footer__logo {
    text-align: center;
    margin-bottom: 40px;
  }

  .footer__logo a,
  .footer__logo img {
    display: inline-block;
  }

  .footer__nav {
    flex-direction: column;
    align-items: normal;
    gap: 0;
  }

  .footer__nav > div {
    padding-bottom: 16px;
    border-top: 1px solid rgba(25, 77, 62, 0.08);
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
  }

  .footer__nav h2 {
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer__nav h2 img {
    display: block;
    transition: 0.15s ease-out;
  }

  img.is-active {
    transform: rotate(180deg);
  }

  .footer__nav ul {
    margin-top: 16px;
  }

  .footer__nav a {
    font-size: 14px;
  }

  .footer__nav h2 {
    font-size: 18px;
    font-family: 'Sora', sans-serif !important;
    margin-bottom: 11px;
  }

  .footer__nav h2 img {
    display: none;
  }

  .footer__nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 11px;
  }

  .footer__nav a {
    text-decoration: none;
    color: #0a1f19;
    opacity: 0.7;
    font-size: 15px;
    transition: 0.15s linear;
  }

  .footer__nav a:hover {
    opacity: 1;
  }

  .footer__legal {
    width: 100%;
    margin-top: 110px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-bottom: 40px;
  }

  .footer__legal small {
    font-size: 9px;
  }
  .footer__legal a {
    color: currentColor;
  }

  .footer__legal a:hover {
    text-decoration: none;
  }
}

/* ===== TABLET */

@media (max-width: 820px) {
  .App {
    padding: 16px;
  }

  .header-list {
    gap: 16px;
  }
}

/* ===== MOBILE */

@media (max-width: 736px) {
  .App {
    padding: 16px;
  }

  .header-toggle {
    display: flex;
  }

  .header-list li {
    display: none;
  }

  .header-list li:last-child {
    display: block;
  }

  header img {
    width: 70px;
    height: auto;
  }

  .mobile-nav--active .mobile-nav {
    display: block;
  }

  .popup {
    padding: 16px;
    justify-content: flex-start;
  }

  .popup__stamp,
  .popup__gold-bar {
    display: none;
  }

  .cta {
    padding: 40px 52px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 40px;
  }

  .cta img {
    position: relative;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    margin-bottom: 23px;
    display: block;
  }

  .cta__content {
    text-align: center;
  }

  .cta__content h2 {
    font-size: 28px;
    margin-bottom: 24px;
    font-weight: normal;
  }

  .footer {
    flex-direction: column;
  }

  .footer__logo {
    text-align: center;
    margin-bottom: 40px;
  }

  .footer__logo a,
  .footer__logo img {
    display: inline-block;
  }

  .footer__nav {
    flex-direction: column;
    align-items: normal;
    gap: 0;
  }

  .footer__nav > div {
    padding-bottom: 16px;
    border-top: 1px solid rgba(25, 77, 62, 0.08);
    margin-left: -16px;
    margin-right: -16px;
    padding: 16px;
  }

  .footer__nav h2 {
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer__nav h2 img {
    display: block;
    transition: 0.15s ease-out;
  }

  img.is-active {
    transform: rotate(180deg);
  }

  .footer__nav ul {
    margin-top: 16px;
  }

  .footer__nav a {
    font-size: 14px;
  }

  .footer__legal {
    margin-top: 0;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid rgba(25, 77, 62, 0.08);
  }
}

.MuiDataGrid-root .MuiDataGrid-columnHeadersInner {
  min-width: auto;
}
